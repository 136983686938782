"use client";

import React, { useEffect, useRef, useState } from "react";

const AutoScrollHooks = ({ hooks, delaySeconds }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % hooks.length);
        setFadeOut(false);
      }, 500); // Wait for fade out to complete
    }, delaySeconds * 1000); // Change hook every few seconds

    return () => clearInterval(intervalRef.current);
  }, [delaySeconds, hooks.length]);

  return (
    <span className={`${fadeOut ? "fade-out" : "fade-in"}`}>
      {hooks[currentIndex]}
    </span>
  );
};

export default AutoScrollHooks;
