/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
"use client";
import Link from "next/link";
import React, { useState } from "react";
import AutoScrollHooks from "../AutoScrollHook";

const ReturnsBanner = () => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const star = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M3.53732 18L9.43349 14.9232L15.2795 17.9936L14.2123 11.4829L19 6.86911L12.4436 5.9223L9.55703 0L6.57255 5.92549L0 6.87877L4.71274 11.4879L3.53732 18Z"
        fill="#B6D1D2"
      />
    </svg>
  );
  const boxShadow = { boxShadow: " 0px 8px 16px 0px rgba(60, 74, 73, 0.25)" };
  return (
    <section
      className={`relative z-10 flex min-h-[90vh] items-center justify-center overflow-hidden pt-[6rem]`}
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 items-center gap-y-6 lg:grid-cols-2">
          <div
            className="wow fadeInUp mx-auto max-w-[750px] p-2"
            data-wow-delay=".2s"
          >
            <h1 className="mb-3 text-3xl font-bold leading-tight text-dark sm:text-4xl sm:leading-tight">
              <AutoScrollHooks
                hooks={[
                  "Tired of High Returns Costs?",
                  "Looking for Sustainable Returns Handling?",
                  "Struggling with Return Fraud?",
                  "Seeking Insights from Return Data?",
                  "Need an AI Powered Returns Solution?",
                  "Ever Thought of Donating Returns?",
                  "Interested in Turning Returns Charitable?",
                  "How About Giving Back with Returns?",
                  "Want to Impact Positively with Returns?",
                  "Returns Piling up?",
                ]}
                delaySeconds={7}
              />
            </h1>

            <p className="mb-6 w-[85%] text-base font-medium  text-dark sm:text-lg md:text-xl">
              LiquiDonate is the first returns platform with sustainability
              built in. You can donate your customer returns directly to
              nonprofits, or upcycle or resell them.
            </p>

            <ul className="mb-6 flex flex-col gap-y-2 pt-4 text-lg text-dark md:text-base lg:mb-10">
              <li className="flex gap-x-2">
                <span className="mt-1">{star}</span>
                <span>50% or more savings on reverse logistics costs</span>
              </li>
              <li className="flex gap-x-2">
                <span className="mt-1">{star}</span>
                <span>Automated return workflows</span>
              </li>
              <li className="flex gap-x-2">
                <span className="mt-1">{star}</span>
                <span>Quality assurance checks</span>
              </li>
              <li className="flex gap-x-2">
                <span className="mt-1">{star}</span>
                <span>Returns matched with nonprofits & upcyclers</span>
              </li>
            </ul>
            <div className="flex justify-center lg:justify-start">
              <div className="flex w-fit flex-col items-center justify-center gap-x-2 gap-y-2 xl:w-full xl:flex-row ">
                <div className="w-full rounded-xl bg-primary duration-300 ease-in-out hover:bg-primary/80 ">
                  <Link
                    href="/demo"
                    className="block w-full px-8 py-3 text-center text-lg font-bold  text-white lg:text-xl xl:text-xl 2xl:text-2xl "
                  >
                    Book a Demo
                  </Link>
                </div>
                <Link
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="ease-in-up w-full items-center justify-center text-center font-bold duration-300 lg:text-2xl"
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.shopify.com/liquidonate"
                >
                  <div className="flex w-full flex-row items-center justify-center rounded-xl border-2 border-primary px-8 py-3 text-center hover:bg-primary hover:bg-opacity-90 hover:text-white hover:shadow-signUp">
                    <span className="text-lg lg:text-xl xl:text-xl 2xl:text-2xl ">
                      Free trial on&nbsp;
                    </span>
                    <img
                      className="max-h-7"
                      src={
                        isHovering
                          ? "/images/brands/shopify_logo_white.png"
                          : "/images/brands/shopify_logo_black.png"
                      }
                      alt="shopify-logo"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="relative my-40 p-2 md:my-60 lg:my-0">
            <div className="absolute flex h-full w-full items-center justify-center">
              <video
                width="672"
                height="374"
                controls
                style={boxShadow}
                className="rounded-lg"
              >
                <source
                  src="/images/magicMatches/video.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReturnsBanner;
