/* eslint-disable @next/next/no-img-element */
"use client";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Featured = ({
  brands,
  data,
}: {
  brands?: boolean;
  data?: { image: string; link?: string }[];
}) => {
  var settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set the autoplay speed in milliseconds
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="bg-primary/5 py-10">
      <h2 className="text-center text-xl font-bold">
        {brands ? "Brands That Trust Us" : "Featured in"}
      </h2>
      <div className="container mx-auto pt-10">
        <Slider {...settings}>
          {data.map((brand, index) => (
            <SingleBrand
              key={index}
              brand={brand}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Featured;

const SingleBrand = ({ brand }: any) => {
  const { link, image, name } = brand;

  return (
    <div className="mx-3 ml-[50px] flex h-28 w-full  items-center justify-center  py-[15px]   md:ml-[0px]  md:w-[90%]  xl:mx-6 2xl:mx-8">
      <a
        href={link}
        target="_blank"
        rel="nofollow noreferrer"
      >
        <img
          src={image}
          alt={name}
          className="h-full w-full"
        />
      </a>
    </div>
  );
};
